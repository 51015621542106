<template>
    <div class="columns is-mobile is-gapless">
        <div class="column">
            <LTooltip
                :label="$t(`common.buttons.${ exclude ? 'exclude' : 'include' }`)"
                :type="typeOfButton('is-danger', 'is-success')">
                <b-button
                    :loading="loading"
                    :type="typeOfButton('is-danger is-light', 'is-success is-light')"
                    :icon-right="typeOfButton('minus', 'plus')"
                    @click="exclude = !exclude">
                </b-button>
            </LTooltip>
        </div>
        <div class="column is-10">
            <slot
                :value="computedValue"
                :exclude="exclude">
                <Component
                    :is="componentInstance"
                    :value="computedValue"
                    :get-data="getData"
                    :get-data-vuex="getDataVuex"
                    :multiple="multiple"
                    :placeholder="placeholder"
                    :seed="seed"
                    :field="field"
                    :prop="prop"
                    :searchable="searchable"
                    :min-required-length="minRequiredLength"
                    @input="select($event)">
                    <template #text="{ option }">
                        <slot
                            name="text"
                            :option="option"></slot>
                    </template>
                    <template #selected-option="{ option }">
                        <slot
                            name="selected-option"
                            :option="option"></slot>
                    </template>
                </Component>
            </slot>
        </div>
    </div>
</template>

<script>
  import BaseSelect from "@/components/Common/Base/BaseSelect.vue";
  import { PropsExcludedSelect } from "@core/mixins/select/propsExcludedSelect";

  export default {
    name: "ExcludeSelect",
    extends: BaseSelect,
    mixins: [PropsExcludedSelect],
    props: {
      lazy: {
        type: Boolean,
        default: false
      },
      searchable: {
        type: Boolean,
        default: true
      }
    },
    
    created () {
      this.localExclude = this.isExcluded;
    },

    data () {
      return {
        localExclude: false
      };
    },

    computed: {
      exclude: {
        get () {
          return this.localExclude;
        },
        set (value) {
          this.localExclude = !this.localExclude;
          if (this.isValueSelected) {
            if(value) {
              this.$emit("update:excludedFilters", this.includedFilters);
              this.$emit("update:includedFilters", null);
            } else {
              this.$emit("update:includedFilters", this.excludedFilters);
              this.$emit("update:excludedFilters", null);
            }
          }
        }
      },

      componentInstance () {
        const name = this.lazy ? "LazySelect" : "Select";
        return () => import(`@/components/Common/Select/${ name }`);
      },

      computedValue () {
        return this.isExcluded ? this.excludedFilters : this.includedFilters;
      },

      isValueSelected () {
        return typeof this.includedFilters?.length === "boolean" || this.includedFilters?.length > 0 || this.isExcluded;
      },

      isExcluded () {
        return typeof this.excludedFilters === "boolean" || this.excludedFilters?.length > 0;
      }
    },
    methods: {
      select (value) {
        if(this.isExcluded || this.exclude) {
          this.$emit("update:excludedFilters", value);
        } else {
          this.$emit("update:includedFilters", value);
        }
      },
      typeOfButton (firstType, secondType) {
        return this.isExcluded || this.exclude ? firstType : secondType;
      }
    }
  };
</script>

<style scoped lang="scss">
  ::v-deep {
    .button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
    }

    .vs__dropdown-toggle {
      border-top-left-radius: 0!important;
      border-bottom-left-radius: 0!important;
    }

    .v-popover, .trigger {
      width: 100%;
    }
  }
</style>